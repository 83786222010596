import create from "zustand";
// import { immer } from "zustand/middleware/immer";
import createAuthSlice, { CreateAuthSliceType } from "./auth";

export type StoreType = CreateAuthSliceType;

const useStore = create<StoreType>()((...a) => ({
  ...createAuthSlice(...a),
}));

// const useStore = create<StoreType>()(
//   immer((...a) => ({
//     ...createAuthSlice(...a),
//   }))
// );

export default useStore;
