import { RefreshQuery } from "@graphql/types";
import fetch from "isomorphic-fetch";
import { AuthConfig } from "@urql/exchange-auth";
import { CombinedError, makeOperation, Operation } from "urql";
import {
  deleteAccessCookie,
  deleteRefreshCookie,
  getAccessCookie,
  getRefreshCookie,
  setAccessCookie,
  setRefreshCookie,
} from "./storage";
import toast from "react-hot-toast";
import jwt_decode, { JwtPayload } from "jwt-decode";

export interface AuthState {
  accessToken?: string;
  refreshToken?: string;
}

export const refreshOnClientError = async (errorOut: boolean = false) => {
  const resultInitial = await fetch(process.env.NEXT_PUBLIC_API_URL!, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `
							query Refresh($refresh_token: String!) {
								refresh(refresh_token: $refresh_token) {
									refresh_token
									access_token
									access_expiry
								}
							}
					`,
      variables: {
        refresh_token: getRefreshCookie() ? getRefreshCookie() : "",
      },
    }),
  });

  const result: { data: RefreshQuery; errors: any } =
    await resultInitial.json();

  if (result.data?.refresh) {
    // save the new tokens in storage for next restart
    setRefreshCookie(result.data.refresh.refresh_token);
    setAccessCookie(result.data.refresh.access_token);
    toast.error("Please refresh your browser window.");
  } else {
    deleteAccessCookie();
    deleteRefreshCookie();
    errorOut && toast.error("Your session has expired. Please login afresh.");
  }
};

// still a work in progress, need to find a way to pass in req and res from context
export const getAuthClient: AuthConfig<AuthState>["getAuth"] = async ({
  authState,
}) => {
  if (!authState) {
    const accessToken = getAccessCookie() as string;
    const refreshToken = getRefreshCookie() as string;

    if (accessToken) {
      return { accessToken, refreshToken };
    }

    return null;
  }

  const resultInitial = await fetch(process.env.NEXT_PUBLIC_API_URL!, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `
										query Refresh($refresh_token: String!) {
								refresh(refresh_token: $refresh_token) {
									refresh_token
									access_token
									access_expiry
								}
							}
					`,
      variables: {
        refresh_token: authState.refreshToken,
      },
    }),
  });

  const result: { data: RefreshQuery; errors: any } =
    await resultInitial.json();

  if (result.data?.refresh) {
    // save the new tokens in storage for next restart
    setRefreshCookie(result.data.refresh.refresh_token);
    setAccessCookie(result.data.refresh.access_token);

    return {
      accessToken: result.data.refresh.access_token,
      refreshToken: result.data.refresh.refresh_token,
    };
  } else {
    // otherwise, if refresh fails, log clear storage and log out
    console.log(
      "Vendors Should be Client RefreshQuery Home page Error :>> ",
      result
    );
    deleteAccessCookie();
    deleteRefreshCookie();

    // your app logout logic should trigger here
    toast.error("Please refresh your browser window.");
    return null;
  }
};

export const addAuthToOperationClient: AuthConfig<AuthState>["addAuthToOperation"] =
  ({
    authState,
    operation,
  }: {
    authState: AuthState;
    operation: Operation;
  }) => {
    // the token isn't in the auth state, return the operation without changes
    if (!authState || !authState.accessToken) {
      return operation;
    }

    // fetchOptions can be a function (See Client API) but you can simplify this based on usage
    const fetchOptions =
      typeof operation.context.fetchOptions === "function"
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      requestPolicy: "network-only",
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          "x-user-token": authState.accessToken
            ? `${authState.accessToken}`
            : "",
        },
      },
    });
  };

export const willAuthErrorClient: AuthConfig<AuthState>["willAuthError"] = ({
  authState,
}) => {
  //if there's no authState the trigger the auth arror
  if (!authState) return true;
  // e.g. check for expiration, existence of auth etc
  const accessTokenDecoded = jwt_decode<JwtPayload>(
    authState.accessToken as string
  );
  const refreshTokenDecoded = jwt_decode<JwtPayload>(
    authState.refreshToken as string
  );

  // if access token will almost expire
  if (
    !!accessTokenDecoded?.exp &&
    Date.now() >= accessTokenDecoded?.exp * 1000
  ) {
    return true;
  }

  // if refresh token will almost expire
  if (
    !!refreshTokenDecoded?.exp &&
    Date.now() >= refreshTokenDecoded?.exp * 1000
  ) {
    return true;
  }
  return false;
};

export const didAuthErrorClient: AuthConfig<AuthState>["didAuthError"] = ({
  error,
}) => {
  return error.graphQLErrors.some((e) => e.message.includes("unauthenticated"));
};

export const onErrorClient = (
  { graphQLErrors, networkError }: CombinedError,
  _operation: Operation
) => {
  if (graphQLErrors)
    if (
      graphQLErrors.some(({ message }) => message.includes("unauthenticated"))
    ) {
      toast.error("Your session has expired.");
    }

  if (networkError) console.log(`[Network error]: ${networkError}`);
};
