import * as React from "react";
import jwt_decode, { JwtPayload } from "jwt-decode";
import Icon from "@components/icon";
import Link from "next/link";
import { useRouter } from "next/router";
import useLockedBody from "@hooks/useLockedBody";
import { deleteAccessCookie, deleteRefreshCookie } from "@utils/storage";
import useStore from "src/store";
import { AnimatePresence, motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import { OperationResult } from "urql";
import { GetMyVendorProfileQuery } from "@graphql/types";
import { refreshOnClientError } from "@utils/auth";

type Props = {
  vendorsProfileResult: OperationResult<GetMyVendorProfileQuery> | null;
};

const Navbar: React.FC<Props> = (props) => {
  const accessToken = useStore((state) => state.accessToken);
  const accessTokenDecoded = !!accessToken
    ? jwt_decode<JwtPayload>(accessToken as string)
    : null;

  //   const [vendorsProfileResult] = useQuery<GetMyVendorProfileQuery>({
  //     query: GetMyVendorProfile,
  //     pause: !accessToken,
  //   });

  if (
    props.vendorsProfileResult?.error?.message.includes("unauthenticated") &&
    !!accessTokenDecoded?.exp &&
    Date.now() >= accessTokenDecoded?.exp * 1000
  ) {
    refreshOnClientError();
  }

  const [show, setShow] = React.useState(false);
  const [search, setSearch] = React.useState(!accessToken);

  React.useEffect(() => {
    setShow(true);

    if (!accessToken) {
      setSearch(true);
    }
  }, [accessToken]);

  const router = useRouter();

  const onPropsPage: boolean = router.pathname.includes("interest");

  const [showDrawer, setShowDrawer] = React.useState(false);

  const [, setLocked] = useLockedBody();

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {showDrawer ? (
          <motion.div
            key={uuidv4()}
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
            className="top-0 left-0 h-screen z-20 fixed w-screen bg-inawo-green py-9 pr-9 pl-14 "
          >
            <div className="w-full max-w-[1300px] flex flex-col justify-start items-start gap-6">
              <div
                className="w-8 h-8 grid scale-150 transform place-items-center place-self-end justify-self-end self-end cursor-pointer mb-4"
                onClick={() => {
                  setLocked(false);
                  setShowDrawer(false);
                }}
              >
                <Icon iconName="close" />
              </div>

              <div className="font-sfpro text-white font-medium text-5xl md:text-7xl flex flex-col justify-start items-start gap-12">
                {!!accessToken && show ? (
                  <>
                    {props.vendorsProfileResult?.data?.getMyVendorProfile.id ? (
                      <Link href={"/dashboard"}>
                        <a className="transition hover:text-inawo-gold">
                          <span className="text-inawo-gold text-base">01</span>
                          Dashboard
                        </a>
                      </Link>
                    ) : (
                      <Link href={"/become-a-vendor"}>
                        <a className="transition hover:text-inawo-gold">
                          <span className="text-inawo-gold text-base">01</span>
                          Become a Vendor
                        </a>
                      </Link>
                    )}
                    <p
                      className="cursor-pointer transition hover:text-inawo-gold"
                      onClick={() => {
                        router.push("/login");
                        //setLocked(false);
                        deleteAccessCookie();
                        deleteRefreshCookie();
                      }}
                    >
                      <span className="text-inawo-gold text-base">02</span>Log
                      out
                    </p>
                  </>
                ) : (
                  <>
                    <Link href={"/login"}>
                      <a className="transition hover:text-inawo-gold">
                        <span className="text-inawo-gold text-base">01</span>
                        Sign In
                      </a>
                    </Link>
                    <Link href={"/register"}>
                      <a className="transition hover:text-inawo-gold">
                        {" "}
                        <span className="text-inawo-gold text-base">02</span>
                        Sign Up
                      </a>
                    </Link>
                    <Link href={"/register?next=become-a-vendor"}>
                      <a className="transition hover:text-inawo-gold">
                        {" "}
                        <span className="text-inawo-gold text-base">03</span>
                        Become a Vendor
                      </a>
                    </Link>{" "}
                  </>
                )}
              </div>
            </div>
          </motion.div>
        ) : (
          <></>
        )}
      </AnimatePresence>

      <div className="flex justify-between items-center w-full py-4 pt-8">
        <div className="flex justify-start gap-1 items-end font-sfpro font-semibold text-xs text-inawo-grey">
          {onPropsPage ? (
            <div className="flex justify-start items-end">
              <Icon iconName="pinGreen" />
              <h2 className="font-sfpro font-semibold text-xl md:text-2xl">
                Interests
              </h2>
            </div>
          ) : (
            <>
              <Link href={"/"}>
                <a className="cursor-pointer">
                  <Icon iconName="logoGreen" />
                </a>
              </Link>
              Vendors
            </>
          )}
        </div>
        <div className="flex justify-center items-center gap-1">
          {search && show && (
            <Link href={"/search"}>
              <a className="cursor-pointer p-2">
                <Icon iconName="search" />
              </a>
            </Link>
          )}
          <div
            className="cursor-pointer p-2"
            onClick={() => {
              setLocked(true);
              setShowDrawer(true);
            }}
          >
            <Icon iconName="burger" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
