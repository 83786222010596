import {
  getAccessCookie,
  getRefreshCookie,
  setAccessCookie,
  setRefreshCookie,
} from "@utils/storage";
import { CookieValueTypes } from "cookies-next";
import { StateCreator } from "zustand";
import { StoreType } from "../index";

interface ContextType {
  req: any;
  res: any;
}

export interface CreateAuthSliceType {
  accessToken: CookieValueTypes;
  setAccessToken: (arg: string, ctx?: ContextType) => void;
  refreshToken: CookieValueTypes;
  setRefreshToken: (arg: string, ctx?: ContextType) => void;
}

const refreshCookie = getRefreshCookie();
const accessCookie = getAccessCookie();

const createAuthSlice: StateCreator<
  StoreType,
  // [["zustand/immer", never]],
  [],
  [],
  CreateAuthSliceType
> = (set) => ({
  accessToken: accessCookie ? accessCookie : "",
  setAccessToken: (arg: string, ctx?: { req: any; res: any }) => {
    set(() => ({ accessToken: arg }));
    if (ctx) {
      setAccessCookie(arg, ctx);
    } else {
      setAccessCookie(arg);
    }
  },
  refreshToken: refreshCookie ? refreshCookie : "",
  setRefreshToken: (arg: string, ctx?: { req: any; res: any }) => {
    set(() => ({ refreshToken: arg }));
    if (ctx) {
      setRefreshCookie(arg, ctx);
    } else {
      setRefreshCookie(arg);
    }
  },
});

export default createAuthSlice;
